<template>
  <div>
    <div class="topNavBox">
      <div class="topbox clearfix">
        <a href="javaScript:" class="fl back" @click="seeBack">
          <img src="~img/back@2x.png" alt="" />
        </a>
        <p class="fl">設置密碼</p>
      </div>
    </div>
    <div class="nick">
      <input
        type="text"
        placeholder="手機號"
        @focus="getFocus"
        v-model="mobile"
      />
    </div>
    <div class="nick">
      <input
        type="text"
        class="getCode"
        placeholder="驗證碼"
        @keyup="getFocus"
        v-model="code"
      />
      <span v-if="isclick" class="fr codeimg" @click="getCode">獲取驗證碼</span>
      <span v-else class="fr codeimg bighint">
        重新發送 ({{ timecount }}s)</span
      >
    </div>
    <div class="nick">
      <input
        type="password"
        placeholder="密碼"
        @keyup="getFocus"
        v-model="password"
      />
    </div>
    <div class="nick">
      <input
        type="password"
        placeholder="確認密碼"
        @keyup="getFocus"
        v-model="repeatPassword"
      />
    </div>
    <button :class="{ submitBtn: true, blue: isBlue }" @click="submits">
      提交
    </button>
  </div>
</template>
<script>
import { setPassword, getCode, getuserInfo } from "apiurl/user";
import { isPwd, encryptStr, isNull } from "utils/core/kernel";
// require("utils/core/g");
export default {
  data() {
    return {
      isBlue: false,
      userInfo: {},
      mobile: "",
      code: "",
      password: "",
      repeatPassword: "",
      isclick: true,
      timer: null, //計時對象
      timecount: 60 //倒計時秒數
    };
  },
  created() {
    this.$nextTick(() => {
      // 獲取用戶信息
      this.getUserInfo();
    });
  },
  methods: {
    seeBack() {
      this.$router.go(-1);
    },
    async getUserInfo() {
      let res = await getuserInfo();
      if (res.success) {
        this.userInfo = res.data;
        this.mobile = this.userInfo.mobile;
      }
    },
    getFocus() {
      if (
        isNull(this.password) ||
        isNull(this.repeatPassword) ||
        isNull(this.code)
      ) {
        this.isBlue = false;
      } else {
        this.isBlue = true;
      }
    },
    /* 獲取手機驗證碼 */
    async getCode() {
      let coderes = await getCode({
        mobile: encryptStr(this.userInfo.mobile)
      });
      if (coderes.success) {
        this.Toast({
          message: "短信已發送",
          duration: 2000,
          position: "top"
        });
        const TIME_COUNT = 60;
        if (!this.timer) {
          this.timecount = TIME_COUNT;
          this.isclick = false;
          this.timer = setInterval(() => {
            if (this.timecount > 1 && this.timecount <= TIME_COUNT) {
              this.timecount--;
            } else {
              this.isclick = true;
              clearInterval(this.timer);
              this.timer = null;
            }
          }, 1000);
        }
      }
    },
    submits() {
      if (
        isNull(this.password) ||
        isNull(this.repeatPassword) ||
        isNull(this.code)
      ) {
        return false;
      } else if (!isPwd(this.password) || !isPwd(this.repeatPassword)) {
        this.Toast({
          message: "六位以上字母數字符號隨意組合",
          duration: 2000,
          position: "top"
        });
      } else {
        let data = this.qs.stringify({
          code: this.code,
          password: encryptStr(this.password),
          repeatPassword: encryptStr(this.repeatPassword)
        });
        setPassword(data).then(res => {
          if (res.success) {
            this.Toast({
              message: res.message,
              duration: 2000,
              position: "top"
            });
            this.$router.go(-1);
          }
        });
      }
    }
  }
};
</script>

<style lang="stylus" scoped>
@import '~@/styles/cominxin.styl'
.nick
    margin 40px 51px 0 44px
    border-bottom 2px solid #D7D7D7
    input
        padding 19px 0px
        width 100%
        font-size 26px
        placeMain(#CFCFCF, 26px, 0px)
        &.getCode
            width 60%
    .codeimg
        width 157px
        height 49px
        background rgba(249, 249, 249, 1)
        border-radius 26px
        border 2px solid rgba(238, 238, 238, 1)
        text-align center
        line-height 49px
        font-size 22px
        display block
        &.bighint
            width 210px
.submitBtn
    width 332px
    height 56px
    background rgba(216, 216, 216, 1)
    border-radius 28px
    font-size 28px
    color #fff
    display block
    margin 298px auto 0
    &.blue
        background linear-gradient(135deg, rgba(122, 209, 255, 1) 0%, rgba(29, 133, 241, 1) 100%)
        box-shadow 0px 1px 8px 0px rgba(118, 206, 254, 1)
</style>
